<template>
<div>
  <div class="full-width cart">
    <div class="container m-b-5">

      <div class="col-7 mobi-space">
        <h1 class="neue title-mobile">Dati di pagamento e spedizione</h1>
        <!-- <p style="color: red">Attenzione: nei prossimi giorni l'invio degli ordini potrebbe subire dei ritardi per i danni subiti dai nostri magazzini a causa del maltempo.<br>Stiamo provvedendo al ripristino del normale servizio.</p> -->

        <h4 class="shipping-title">Dati di pagamento</h4>
        <hr>

        <div class="row spaced">
          <div class="col-6">
            <input type="text" placeholder="Nome" v-model="model.name" />
          </div>
          <div class="col-6">
            <input type="text" placeholder="Cognome" v-model="model.surname" />
          </div>
        </div>

        <div class="row spaced">
          <div class="col-6">
            <input type="text" placeholder="Email" v-model="model.email" />
          </div>
          <div class="col-6">
            <input type="text" placeholder="Telefono" v-model="model.phone" />
          </div>
        </div>

        <div class="row spaced" v-if="!$store.state.user.loggedIn">
          <div class="col-12">
            <input type="checkbox" v-model="miregistro" /> Crea un account
          </div>
        </div>

        <div class="fattura" v-show="miregistro">
          <div class="row spaced">
            <div class="col-6">
              <input type="password" placeholder="Password" v-model="register.password" />
            </div>
            <div class="col-6">
              <input type="password" placeholder="Conferma password" v-model="register.confirm" />
            </div>
          </div>

          <div class="row spaced">
            <div class="col-12">
              Ho letto e accettato la <a href="https://www.altromercato.it/privacy-policy/" target="_blank">Privacy Policy</a> per il trattamento dei miei dati personali.
            </div>
          </div>

          <div class="row spaced">
            <div class="col-12">
              <input type="checkbox" v-model="register.newsletter" /> Acconsento al trattamento dei miei dati personali per finalità di marketing (quali newsletter, promozioni novità) come ai sensi dell'articolo 3 della <a href="/pages/politica-di-privacy" target="_blank">privacy policy</a>
            </div>
          </div>
        </div>

        <div v-if="!onlyGift">
          <h2 style="font-size: 1.1em;margin:50px 0 15px">SCEGLI IL METODO DI RITIRO</h2>
          <div class="shippingSwitch__wrapper">
            <div class="shippingSwitch neue" @click="clickAndCollect=!clickAndCollect">
              <span :class="!clickAndCollect ? 'active' : ''">
                Ricevilo a casa
              </span>
              <span :class="clickAndCollect ? 'active' : ''">
                Ritiro in negozio
              </span>
            </div>
            <div class="shippingSwitch__tip">Spedizione gratuita se ritiri in negozio</div>
          </div>
          <h4 v-show="clickAndCollect" class="shipping-title">Scegli lo store</h4>
          <div v-show="clickAndCollect" class="neue" style="margin: 12px 0 13px 0;">Verifica eventuali chiusure estive del punto vendita cliccando sulla scheda "Informazioni".</div>
          <hr v-show="clickAndCollect">
          <div v-show="clickAndCollect && clickAndCollectSelected === false" class="cNc__wrapper neue">
            <div class="cNc__locationsWrapper">
              <div class="cNc__locationsSelect">
                <div class="cNc__locationsLabel" @click="e=>{e.stopPropagation();clickAndCollectLocationListOpen=!clickAndCollectLocationListOpen;}">{{clickAndCollectLocationProvincia === false ? (clickAndCollectLocationRegione === false ? 'Località' : clickAndCollectLocationRegione) : clickAndCollectLocationProvincia}}<span class="fa fa-chevron-down"></span></div>
                <div class="cNc__locationsList" v-if="clickAndCollectLocationListOpen">
                  <div class="cNc__locationsRegione" @click="clickAndCollectLocationRegione = false;clickAndCollectLocationProvincia = false;updateClickAndCollectMarkers()">Tutte le località</div>
                  <div v-for="regione in Object.keys(clickAndCollectRegions).sort()" v-bind:key="regione">
                    <div class="cNc__locationsRegione" @click="clickAndCollectLocationRegione = regione;clickAndCollectLocationProvincia = false;updateClickAndCollectMarkers()">{{regione}}</div>
                    <div v-for="provincia in Object.keys(clickAndCollectRegions[regione]).sort()" v-bind:key="provincia" class="cNc__locationsProvincia" @click="clickAndCollectLocationRegione = regione;clickAndCollectLocationProvincia = provincia;updateClickAndCollectMarkers()">
                      {{provincia}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="cNc__puntivenditaTitle">Punti vendita nella mappa</div>
              <div class="cNc__puntivenditaList">
                <div class="cNc__puntovendita" v-for="puntoVendita in clickAndCollectLocations.filter(pv => (clickAndCollectLocationRegione === false || clickAndCollectLocationRegione === pv.regione) && (clickAndCollectLocationProvincia === false || clickAndCollectLocationProvincia === pv.provincia)).sort((a,b) => a.nome > b.nome ? 1 : -1)" v-bind:key="puntoVendita.id" @click="clickAndCollectSelected=puntoVendita">
                  <div class="cNc__puntovenditaTitle">{{puntoVendita.nome}}</div>
                  <div>{{puntoVendita.via}}, {{puntoVendita.civico}} {{puntoVendita.citta}} {{puntoVendita.sigla_provincia}} {{puntoVendita.cap}}</div>
                  <a :href="puntoVendita.url" target="_blank" @click="e => e.stopPropagation()" v-if="puntoVendita.url">Informazioni</a>
                </div>
              </div>
            </div>
            <div class="cNc__mapWrapper">
              <div id="clickAndCollectMap"></div>
            </div>
          </div>
          <div v-show="clickAndCollect && clickAndCollectSelected !== false" class="cNcSelected__wrapper neue">
            <div style="font-weight:bold">{{clickAndCollectSelected.nome}}</div>
            <div>{{clickAndCollectSelected.via}}, {{clickAndCollectSelected.civico}} {{clickAndCollectSelected.citta}} {{clickAndCollectSelected.sigla_provincia}} {{clickAndCollectSelected.cap}}</div>
            <a :href="clickAndCollectSelected.url" target="_blank" v-if="clickAndCollectSelected.url">Informazioni</a>
            <div style="margin:10px 0">
              <div class="button" @click="clickAndCollectSelected = false">
                Scegli un altro punto vendita
              </div>
            </div>
          </div>
          <div v-show="!clickAndCollect">
            <h4 class="shipping-title">Dati di spedizione</h4>
            <hr>

            <div class="row spaced">
              <div class="col-6">
                <input type="text" placeholder="Nome destinatario" v-model="model.shippingName" />
              </div>
              <div class="col-6">
                <input type="text" placeholder="Cognome destinatario" v-model="model.shippingSurname" />
              </div>
            </div>

            <div class="row spaced">
              <div class="col-6">
                <input type="text" placeholder="Città" v-model="model.shippingCity" />
              </div>
              <div class="col-6">
                <input type="text" placeholder="CAP" v-model="model.shippingCap" />
              </div>
            </div>

            <div class="row spaced">
              <div class="col-6">
                <input type="text" placeholder="Via" v-model="model.shippingStreet" />
              </div>
              <div class="col-6">
                <input type="text" placeholder="Numero civico" v-model="model.shippingCivic" />
              </div>
            </div>

            <div class="row spaced">
              <!--<div class="col-6">
                <input type="text" placeholder="Addizionale" v-model="model.shippingAdditional" />
              </div>-->
              <div class="col-6">
                <!--<input type="text" placeholder="Provincia" v-model="model.shippingProvince" />-->
                <select placeholder="Provincia" v-model="model.shippingProvince">
                  <option value="">Provincia</option>
                  <option :value="item.sigla" v-for="(item, k) in province" v-bind:key="k">{{item.nome}}</option>
                </select>
              </div>
              <!--<div class="col-6">
                <input type="text" placeholder="Indirizzo completo" v-model="model.shippingAddress" />
              </div>-->
              <div class="col-6">
                <input type="text" placeholder="c/o (massimo 20 caratteri)" v-model="model.shippingCO" maxlength="20" />
              </div>
            </div>
          </div>
        </div>
        <div class="row spaced">
          <div class="col-12">
            <input type="checkbox" v-model="fattura" /> Desidero ricevere fattura
          </div>
        </div>

        <div class="fattura" v-show="fattura">

          <div class="row spaced">
            <div class="col-12">
              <!-- <p style="color:red">Causa chiusura dei nostri uffici amministrativi, gli ordini con richiesta fattura saranno inviati a partire dal 22&nbsp;agosto.</p> -->
            </div>
          </div>

          <h4 class="shipping-title">Dati di fatturazione</h4>
          <hr>

          <div class="row spaced">
            <div class="col-12">
              <input type="text" placeholder="Ragione Sociale" v-model="model.companyName" />
            </div>
          </div>
          <div class="row spaced">
            <div class="col-6">
              <input type="text" placeholder="Partita IVA" v-model="model.pIva" />
            </div>
            <div class="col-6">
              <input type="text" placeholder="Codice Fiscale" v-model="model.fiscalCode" />
            </div>
          </div>

          <div class="row spaced">
            <div class="col-6">
              <input type="text" placeholder="Codice SDI" v-model="model.uniqueCode" />
              <!--<p class="small no-mobi">Se non in possesso di codice SDI, indicare 0000000 (7 volte 0)</p>-->
            </div>
            <div class="col-6">
              <input type="text" placeholder="PEC" v-model="model.pec" />
            </div>
          </div>

          <div class="row spaced">
            <div class="col-6">
              <input type="text" placeholder="Città di fatturazione" v-model="model.billingCity" />
            </div>
            <div class="col-6">
              <input type="text" placeholder="CAP di fatturazione" v-model="model.billingCap" />
            </div>
          </div>

          <div class="row spaced">
            <div class="col-6">
              <input type="text" placeholder="Via di fatturazione" v-model="model.billingStreet" />
            </div>
            <div class="col-6">
              <input type="text" placeholder="Numero civico di fatturazione" v-model="model.billingCivic" />
            </div>
          </div>

          <div class="row spaced">
            <div class="col-6">
              <input type="text" placeholder="Provincia di fatturazione" v-model="model.billingProvince" />
            </div>
            <!--<div class="col-6">
              <input type="text" placeholder="Indirizzo completo di fatturazione" v-model="model.billingAddress" />
            </div>-->
          </div>
        </div>
      </div>

      <div class="col-5 totals">

        <div v-if="total <= 0" style="margin-top: 60px">
          <center>
            <h4>Hai scelto di utilizzare una Gift Card o Coupon di sconto che copre totalmente il subtotale del tuo carrello. Procedi con la richiesta cliccando su "Completa ordine".</h4>
          </center>
        </div>

        <h3 class="payment-method" v-if="total > 0">Metodo di pagamento</h3>

        <div class="row subtotal-row" v-if="total > 0">
          <div class="col-12">
            <input type="radio" value="MP23" name="method" v-model="model.payment" />
            Paga con carta di credito
          </div>
        </div>

        <!--  v-if="$store.state.user.loggedIn && $store.state.user.data.email == 'alessandro.caligaris1991@gmail.com'" -->
        <div class="row subtotal-row" v-if="total > 0">
          <div class="col-12">
            <input type="radio" value="MP25" name="method" v-model="model.payment" />
            Paga con il tuo conto PayPal
          </div>
        </div>

        <div class="row subtotal-row" v-if="total > 0">
          <div class="col-12">
            <input type="radio" value="MP24" name="method" v-model="model.payment" />
            Paga con Satispay
          </div>
        </div>

        <hr class="m-t-36">
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            Sub totale
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice(subTotal)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="hasPromo">
          <div class="col-6 align-center">
            Promozioni
          </div>
          <div class="col-6 align-center">
            - &euro; {{getPrintablePrice(promoDiscount)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="$store.state.discount" style="height:auto">
          <div class="col-6 align-center">
            <span style="white-space:nowrap">Codice <b>{{$store.state.discount.code}}</b></span>
            <div v-if="cart.filter(item => $utils.isGift(item.item.itemNumber)).length && !onlyGift" style="line-height:1;font-size:.8em;">Il codice sconto viene applicato a tutti i prodotti del carrello, ad eccezione del prodotto Gift Card.</div>
            <div v-if="onlyGift" style="line-height:1;font-size:.8em;">Non è possibile applicare il codice sconto per l'acquisto di Gift Card.</div>
            <div v-if="$store.state.discount.code.match(/^GC-[A-Z]{1,6}-[A-Z0-9]{10}$/) && ($store.state.discount.single == 1) && parseFloat($store.state.discount.value) > parseFloat(scontoBuono)" style="line-height:1;font-size:.8em;color:#a00">Ti ricordiamo che il valore della Gift Card applicata può essere utilizzato in un solo ordine.<br>Non è previsto un credito residuo.</div>
          </div>
          <div class="col-6 align-center" v-if="scontoBuono">
            - &euro; {{getPrintablePrice(scontoBuono)}}
          </div>
          <div class="col-6 align-center" v-if="!scontoBuono">
            &euro; {{getPrintablePrice(scontoBuono)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="$store.state.donation">
          <div class="col-6 align-center">
            Donazione
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice($store.state.donation ? $store.state.donation : 0)}}
          </div>
        </div>
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            Spedizione
          </div>
          <div class="col-6 align-center">
            &euro; {{ getPrintablePrice(shipping) }}
          </div>
        </div>
        <hr>
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            <b>TOTALE</b>
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice(total)}}
          </div>
        </div>
        <div class="row spaced m-t-10">
          <div v-if="total > 0 && total < 1" class="col-12" style="font-size: .8em; color: #a00;">Per motivi tecnici legati ai portali di pagamento non è possibile procedere con ordini di importo inferiore a 1€.</div>
          <div class="col-12" v-show="isAlcool()" v-else>
            <p>Sono presenti uno o più prodotti alcolici nel tuo carrello. Conferma la tua maggiore età</p>
            <input type="checkbox" v-model="alcoolCheck" /> Confermo di avere almeno 18 anni
          </div>
        </div>
        <div class="align-right mobi-center m-t-10" v-if="total > 0 && total < 1">
          <div class="button button-fondo button-disabled">
            Ordina ora
          </div>
        </div>
        <div class="align-right mobi-center m-t-10" v-else>
          <div class="button" style="padding:7px 58px;cursor:unset;" v-if="settings.isOrdered === true">
              <Spinner size="small" line-fg-color="#fff" line-bg-color="#555555" />
          </div>
          <div class="button button-fondo" @click="confirmOrder" v-if="settings.isOrdered === false">
            {{ total > 0 ? 'Ordina ora' : 'Completa ordine' }}
          </div>
          <p style="color: red" class="neue align-left" v-html="stockError"></p>
        </div>
      </div>

    </div>
  </div>

  <!--<div class="full-width gray-spacer"></div>
  <div class="full-width hero lesser">
    <div class="col-7">
      <img src="@/assets/home2.png" class="cover-img" />
    </div>
    <div class="col-5 center-content">
      <div class="button-square m-b-5">I nostri valori</div>
      <p>Design made in Italy e<br/><b>valori fatti a mano</b></p>
      <div class="button">scopri perch&eacute;</div>
    </div>
  </div>
  <div class="full-width gray-spacer"></div>-->
</div>
</template>

<script>
import axios from 'axios'
import province from '@/mockups/province'
import Spinner from 'vue-simple-spinner'
import clickandcollectmarker from '@/assets/clickandcollectmarker.png'

export default {
  name: 'Checkout',
  components : {
    Spinner,

  },
  data: function () {
    return {
      settings:{
        isOrdered : false,
        shippingCost: 7.5,
        clickAndCollectShippingCost: 0
      },
      onlyGift: false,
      fattura: false,
      miregistro: false,
      subTotal: 0,
      sconto: 0,
      scontoBuono: 0,
      categoryFreeshipping: [],

      register: {
        password: '',
        confirm: '',
        newsletter: false
      },
      map: false,

      province,

      fieldLabels: {
        payment: 'Pagamento',
        name: 'Nome',
        surname: 'Cognome',
        email: 'Email',
        phone: 'Telefono',
        /*company: 'Azienda',*/
        companyName: 'Ragione Sociale',
        pIva: 'Partita IVA o Codice Fiscale',
        fiscalCode: 'Codice Fiscale',
        uniqueCode: 'Codice Univoco',
        pec: 'PEC',
        billingCity: 'Città di fatturazione',
        billingCap: 'CAP di fatturazione',
        billingStreet: 'Via di fatturazione',
        billingCivic: 'Numero Civico di fatturazione',
        billingProvince: 'Provincia di fatturazione',
        shippingName: 'Nome destinatario',
        shippingSurname: 'Cognome destinatario',
        shippingCity: 'Città',
        shippingCap: 'CAP',
        shippingStreet: 'Via',
        shippingCivic: 'Numero Civico',
        shippingProvince: 'Provincia',
        clickAndCollectSelected: 'Seleziona un punto di ritiro',
      },
      model: {
        payment: 'MP23',
        name: this.$store.state.user.data.name || '',
        surname: this.$store.state.user.data.surname || '',
        email: this.$store.state.user.data.email || '',
        phone: this.$store.state.user.data.phone || '',
        //company: '',
        companyName: '',
        pIva: '',
        fiscalCode: '',
        uniqueCode: '',
        pec: '',
        billingCity: '',
        billingCap: '',
        billingStreet: '',
        billingCivic: '',
        billingProvince: '',
        shippingName: this.$store.state.user.data.name || '',
        shippingSurname: this.$store.state.user.data.surname || '',
        shippingCity: this.$store.state.user.data.shippingCity || '',
        shippingCap: this.$store.state.user.data.shippingCap || '',
        shippingStreet: this.$store.state.user.data.shippingStreet || '',
        shippingCivic: this.$store.state.user.data.shippingCivic || '',
        shippingProvince: this.$store.state.user.data.shippingProvince || '',
        shippingCO: '',
      },
      alcoolCheck: false,
      stockError: '',

      validations: {
        name: function (val) { return val.length > 0 },
        surname: function (val) { return val.length > 0 },
        email: (val) => {
          return this.isEmail(val)
        },
        phone: function (val) { return /^[0-9 .+-]+$/.test(val) },
        companyName: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        pIva: (val) => { return !this.fattura || this.fattura && val.length == 11 || this.fattura && this.model.fiscalCode.length > 0 },
        fiscalCode: () => { return true },
        uniqueCode: () => { return true },
        pec: () => { return true },
        billingCity: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        billingCap: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        billingStreet: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        billingCivic: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        billingProvince: (val) => { return !this.fattura || this.fattura && val.length > 0 },
        shippingName: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingSurname: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingCity: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingCap: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingStreet: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingCivic: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        shippingProvince: (val) => { return this.clickAndCollect || this.onlyGift || val.length > 0 },
        clickAndCollectSelected: () => { return !this.clickAndCollect || this.clickAndCollectSelected },
      },
      clickAndCollect: false,
      clickAndCollectLocations: [],
      clickAndCollectRegions: {},
      clickAndCollectLocationListOpen: false,
      clickAndCollectLocationRegione: false,
      clickAndCollectLocationProvincia: false,
      clickAndCollectSelected: false,
    }
  },

  metaInfo() {
    this.$utils.adabraPageView(this, { pageType: 110, pageTitle: 'Checkout' });
    return {
      title: 'Checkout'
    }
  },

  computed: {
    total () {
      return (parseFloat(this.subTotal) + this.shipping).toFixed(2) - parseFloat(this.sconto).toFixed(2) + (this.$store.state.donation ? this.$store.state.donation : 0)
    },
    freeShipping () {
      if(this.$store.state.discount && (this.$store.state.discount.sogliaFreeShipping)) {
        return this.$store.state.discount.sogliaFreeShipping;
      }
      return 59;
    },
    shipping: function () {
      if(!this.cart.filter(item => !this.$utils.isGift(item.item.itemNumber)).length) {
        return 0;
      }
      if(this.cart.find(item => item.item.categories.find(cat => this.categoryFreeshipping.includes(cat.categoryCode)))) {
        return 0;
      }
      if(this.$store.state.discount && (this.$store.state.discount.freeshipping == 1)) {
        if(!this.$store.state.discount.category) {
          return 0;
        }
        if(this.cart.find(item => item.item.categories.find(cat => cat.categoryCode === this.$store.state.discount.category))) {
          return 0;
        }
      }
      if(this.freeShipping <= parseFloat(this.subTotal)) {
        return 0;
      }
      return this.clickAndCollect ? this.settings.clickAndCollectShippingCost : this.settings.shippingCost;
    },
    cart () {
     return this.$store.state.cart.map((item) => {
        return {
          ...item,
          tent: false,
          item: {
            ...item.item,
            scontoDipendenti: this.$utils.getScontoDipendenti(item)
          }
        }
      })
    },
    hasPromo () {
      return !this.$store.state.user.data.has_sconto_dipendenti && this.$utils.hasPromo(this.cart)
    },
    promos () {
      if(this.$store.state.user.data.has_sconto_dipendenti) {
        return [];
      }
      return this.$utils.getPromos(this.cart)
    },
    promoDiscount () {
      return this.$utils.getPromoDiscount(this.cart, this.$utils.getTotalInPromo(this.cart))
    }
  },

  mounted () {
    if(!this.$store.state.cart || !this.$store.state.cart.length) {
      // eslint-disable-next-line no-empty
      try { this.$router.replace('/cart'); } catch (e) { }
      return false
    }
    this.$utils.checkCart(this)
    axios.get('https://shop.altromercato.it/cms/2/category_freeshipping')
      .then((res) => {
        this.categoryFreeshipping = res.data;
        this.sconto = 0
        this.checkGifts();
        let gtmItems = []
        this.cart.forEach((item) => {
          this.subTotal += this.$utils.getProductPrice(item.item, false, this.$store.state.user.data.has_sconto_dipendenti) * item.qty
          gtmItems.push({
            item_name: item.item.productName,
            item_id: item.item.itemNumber,
            item_brand: item.item.brand,
            item_category: item.item.categories[item.item.categories.length - 1].categoryName,
            item_variant: item.item.fairTradeSuppliers ? item.item.fairTradeSuppliers[0] : '',
            name: item.item.productName,
            id: item.item.itemNumber,
            brand: item.item.brand,
            category: item.item.categories[item.item.categories.length - 1].categoryName,
            variant: item.item.fairTradeSuppliers ? item.item.fairTradeSuppliers[0] : '',
            price: item.item.productPrice,
            quantity: item.qty
          })
        })

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          'event': 'checkout',
          'ecommerce': {
            'checkout': {
              'actionField': {'step': 1, 'option': 'Inizio checkout'},
              'products': gtmItems
            }
          }
        })

        this.subTotal = this.subTotal.toFixed(2)

        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "begin_checkout",
          ecommerce: {
            currency: "EUR",
            value: this.subTotal,
            items: gtmItems
          }
        });

        if (this.$store.state.discount) {
          const partialSubTotal = this.cart
            .filter(item => !this.$utils.isGift(item.item.itemNumber))
            .reduce((tot, item) => tot + this.$utils.getProductPrice(item.item, false, this.$store.state.user.data.has_sconto_dipendenti) * parseFloat(item.qty), 0)
          if (this.$store.state.discount.percent) {
            this.sconto = ( partialSubTotal / 100 ) * this.$store.state.discount.percent
            this.scontoBuono = ( partialSubTotal / 100 ) * this.$store.state.discount.percent
          } else {
            const scontoBuono = Math.min(parseFloat(partialSubTotal), parseFloat(this.$store.state.discount.value))
            this.sconto = parseFloat(scontoBuono)
            this.scontoBuono = scontoBuono
          }
        }

        if (this.hasPromo) {
          this.sconto += this.$utils.getPromoDiscount(this.cart, this.$utils.getTotalInPromo(this.cart))
        }

       })
      .catch((err) => console.log(err))

    this.map = new window.google.maps.Map(document.getElementById("clickAndCollectMap"), {
      center: {lat: 41.87194, lng: 12.56738},
      zoom: 4,
      styles: this.$config.mapStyles,
      zoomControl: false,
      disableDefaultUI: true
    });
    axios.get('https://shop.altromercato.it/cms/2/click_and_collect_locations')
      .then((res) => {
        const markerIcon = {
          url: clickandcollectmarker,
          scaledSize: new window.google.maps.Size(24, 30),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(12, 30)
        };
        this.clickAndCollectLocations = res.data.map((loc) => {
          const marker = new window.google.maps.Marker({
            position: {lat: parseFloat(loc.lat), lng: parseFloat(loc.lon)},
            map: this.map,
            icon: markerIcon,
            title: loc.nome,
          })
          window.google.maps.event.addListener(marker, 'click', () => {this.clickAndCollectSelected = loc;})
          return {...loc, marker}
        })
        this.clickAndCollectLocations.forEach((loc) => {
          this.clickAndCollectRegions[loc.regione] = this.clickAndCollectRegions[loc.regione] || {}
          this.clickAndCollectRegions[loc.regione][loc.provincia] = this.clickAndCollectRegions[loc.regione][loc.provincia] || []
          this.clickAndCollectRegions[loc.regione][loc.provincia].push(loc.id)
        })
        this.updateClickAndCollectMarkers();
      })
    window.addEventListener('click', this.closeClickAndCollectLocationList);
  },

  beforeDestroy () {
    window.removeEventListener('click', this.closeClickAndCollectLocationList);
  },

  methods: {
    updateClickAndCollectMarkers() {
      const bounds = new window.google.maps.LatLngBounds();
      this.clickAndCollectLocations.forEach((loc) => {
        const visible = (this.clickAndCollectLocationRegione === false || this.clickAndCollectLocationRegione === loc.regione) && (this.clickAndCollectLocationProvincia === false || this.clickAndCollectLocationProvincia === loc.provincia);
        if(visible) {
          bounds.extend(loc.marker.getPosition());
        }
        loc.marker.setVisible(visible);
      })
      this.map.fitBounds(bounds);
      if(this.map.getZoom() > 16) this.map.setZoom(16);
    },
    closeClickAndCollectLocationList () {
      this.clickAndCollectLocationListOpen = false;
    },
    checkGifts () {
      let onlyGift = true;
      this.cart.forEach((item) => {
        if(!this.$utils.isGift(item.item.itemNumber)) {
          onlyGift = false;
        }
      })
      this.onlyGift = onlyGift;
    },
    isEmail (val) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(val).toLowerCase());
    },
    isAlcool () {
      let alcool = false
      this.$store.state.cart.forEach((item) => {
        if (item.item.productCategory == 'EC81500') {
           alcool = true
        }
      })

      return alcool
    },

    async confirmOrder() {
      if(!this.$store.state.cart || !this.$store.state.cart.length) {
        // eslint-disable-next-line no-empty
        try { this.$router.replace('/cart'); } catch(e) { }
        return false
      }

      this.settings.isOrdered = true
      if(!(await this.$utils.checkCart(this))) {
        this.settings.isOrdered = false
        return false
      }

      if (this.isAlcool() && !this.alcoolCheck) {
        this.$toast.open('Conferma di essere maggiorenne per continuare')
        return false
      }

      if (this.miregistro) {
        if (this.register.password.length < 8) {
          this.$toast.open('Inserisci una password di almeno 8 caratteri')
          return false
        }

        if (this.register.password != this.register.confirm) {
          this.$toast.open('Le due password devono coincidere')
          return false
        }
      }

      let ok = true
      let invalidFields = []

      for (let index in this.validations) {
        if (!this.validations[index](this.model[index])) {
          invalidFields.push(this.fieldLabels[index])
          ok = false
          this.settings.isOrdered = false
        }
      }

      /*try {
        if (!this.$caps[this.model.shippingProvince.toUpperCase()].includes(this.model.shippingCap)) {
          this.$toast.open('Controlla il CAP inserito')
          this.settings.isOrdered = false
          return false
        }
      } catch (e) {
        this.$toast.open('Controlla il CAP inserito')
        this.settings.isOrdered = false
        return false
      }*/

      if (!ok) {
        this.$toast.open('I seguenti campi non sono validi: ' + invalidFields.join(', '))
        this.settings.isOrdered = false
        return false
      }


      if (this.miregistro) {
        try {
          let registerData = {
            password: this.register.password,
            confirm: this.register.confirm,
            newsletter: this.register.newsletter,
            ...this.model
          }

          let resp = await axios.post(this.$config.baseUrl + 'register.php', JSON.stringify(registerData))
          await axios.post(this.$config.backendUrl + 'index.php?action=register', JSON.stringify(registerData))

          if (resp.data.status == 'KO') {
            this.$toast.open('Questa mail è già stata utilizzata per registrarsi')
            this.settings.isOrdered = false
            return false
          } else {
            this.$store.commit('login', registerData)
          }
        } catch (e) {
          this.$toast.open('Qualcosa è andato storto')
          this.settings.isOrdered = false
          return false
        }
      }

      try {
        this.model.shipping = this.shipping

        if(this.fattura) {
          this.model.fattura = ''
        } else {
          if(
            this.$store.state.discount &&
            (this.$store.state.discount.single == 1) &&
            (this.$store.state.discount.freeshipping == 1) &&
            this.$store.state.discount.code.match(/^GC-[A-Z]{1,6}-[A-Z0-9]{10}$/)
          ) {
            if(this.$config.backendUrl === 'https://shop.altromercato.it/staging/') {
              // staging
              this.model.fattura = 'CA689'
            } else {
              // produzione
              this.model.fattura = 'CA990'
            }
          } else if(this.$store.state.discount &&
            this.$store.state.discount.code.match(/_[DVTQF]AZ$/)) {
            if(this.$config.backendUrl === 'https://shop.altromercato.it/staging/') {
              // staging
              this.model.fattura = 'CA689'
            } else {
              // produzione
              this.model.fattura = 'CA990'
            }
          } else {
            this.model.fattura = 'C3305'
          }
        }

        if(!this.clickAndCollect && !this.onlyGift) {
          this.$store.commit('addAddress', this.model)
        }

        if(this.onlyGift) {
          this.model.shippingName = this.model.name
          this.model.shippingSurname = this.model.surname
          this.model.shippingCity = 'Verona'
          this.model.shippingCap = '37135'
          this.model.shippingStreet = 'Via Francia'
          this.model.shippingCivic = '1/C'
          this.model.shippingProvince = 'VR'
        }

        let stockProblems = await axios.post(this.$config.backendUrl + 'index.php?action=stock', JSON.stringify({
          form: this.model,
          cart: this.$store.state.cart
        }))

        if (stockProblems.data.length) {
          let msg = 'Alcuni articoli non sono disponibili a magazzino: <br>'
          stockProblems.data.forEach((itm) => {
            msg += itm.item.productName + '<br>'
          })
          this.stockError = msg
          this.settings.isOrdered = false
          return false;
        }

        let hasBuonoValore = this.scontoBuono && this.$store.state.discount.value
        let percentualeBuono = 0
        if (hasBuonoValore) {
          let totaleOrdine = parseFloat(this.subTotal)
          percentualeBuono = (100 * parseFloat(this.scontoBuono)) / totaleOrdine
        }

        if (this.total <= 0) {
          this.model.payment = 'MP15'
        }

        let resp = await axios.post(this.$config.backendUrl + 'index.php?action=pay', JSON.stringify({
          status: 2,
          form: {...this.model, clickAndCollect: this.clickAndCollect ? this.clickAndCollectSelected.id : false },
          discount: this.$store.state.discount,
          scontoDelBuono: hasBuonoValore ? percentualeBuono : false,
          scontoInValore: this.scontoBuono,
          promos: this.promos,
          cart: this.getActualCart(),
          donation: this.$store.state.donation,
          destinatari: this.$store.state.destinatari
        }))

        if (resp.data != 'KO') {
          this.$store.commit('removeCoupon')

          window.dataLayer.push({ ecommerce: null });
          window.dataLayer.push({
            event: "checkoutOption",
            ecommerce: {
              checkout_option: {
                actionField: {'step': 2, 'option': 'Ordina ora'}
              }
            }
          })

          location.href = resp.data.url
        } else {
          this.$toast.open('Qualcosa è andato storto')
          this.settings.isOrdered = false
          return false
        }
      } catch (e) {
        this.$toast.open('Qualcosa è andato storto')
        this.settings.isOrdered = false
      }
    },

    getPrintablePrice (price) {
      return ((parseFloat(price)).toFixed(2) + "").replace('.', ',')
    },

    getActualCart () {
      if(!this.$store.state.user.data.has_sconto_dipendenti) {
        return this.$store.state.cart
      }
      return this.$store.state.cart.map((item) => {
        return {
          ...item,
          item: {
            ...item.item,
            sconto: item.item.sconto ? item.item.sconto : this.$utils.getScontoDipendenti(item)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.cart h1 {
  font-weight: 600;
  font-size: 38px;
  margin: 40px 0;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-style:none none solid;
  border-collapse: collapse;
  margin-bottom: 46px;
  border: 0px solid black;
}

.cart .container.column {
  flex-direction: column;
}

thead tr {
  border-bottom: 1px solid #cfcfcf;
  height: 40px;
  line-height: 40px;
}

thead th {
  text-align: left;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

tbody tr {
  height: 120px;
  border-bottom: 1px solid #cfcfcf;
}

.cart-image img {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
}

.cart-name {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.cart-discount {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
}

.prod-qty {
  display: inline-block;
  margin-right: 15px;
}

.fa-trash {
  cursor: pointer;
}

.coupon-code {
  background-color: #fff;
  text-align: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.button {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.coupon-description {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
}

hr {
  border: 1px solid #e3e3e3;
}

p {
  line-height: 140%;
}

.subtotal-row {
  height: 32px;
  line-height: 32px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.totals {
  padding-left: 40px;
}

.interested-in {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

input[type="text"],
input[type="password"],
select
{
  background-color: #fff;
  outline: 0;
  width: 100%;
  margin-right: 15px;
  height: 32px;
  line-height: 32px;
  padding-left: 10px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #ddd;
  border-radius: 16px;
}

.row.spaced {
  margin-bottom: 20px;
}

.row.spaced .col-6,
.row.spaced .col-12 {
  padding-right: 15px;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.payment-method {
  margin-top: 185px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: neue-haas-grotesk-display, sans-serif;
  text-transform: uppercase;
}

.fattura {
  margin-bottom: 60px;
}

.shipping-title {
  margin: 0 0 10px 0;
}

.shipping-title + hr {
  margin-bottom: 20px;
}

@media (max-width: 680px) {
  .row.spaced {
    display: flex;
    justify-content: space-between;
  }

  .row.spaced .col-6,
  .row.spaced .col-12 {
    padding-right: 0;
  }

  .row.spaced .col-6 {
    width: 48%;
  }

  .totals {
    padding: 0 10px;
  }

  .payment-method {
    margin-top: 20px;
  }
}
.shippingSwitch__wrapper {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.shippingSwitch {
  border: 1px solid #000;
  border-radius: 25px;
  display: inline-block;
  background: #e3e3e3;
  font-weight: 700;
  flex-shrink: 0;
  margin: 10px 10px 10px 0;
  font-size: 12px;
  white-space: nowrap;
}
@media (max-width: 500px) {
  .shippingSwitch__wrapper {
    flex-direction: column;
  }
  .shippingSwitch {
    margin-right: 0;
  }
}
.shippingSwitch span {
  display: inline-block;
  padding: 8px 40px;
  cursor: pointer;
  border-radius: 25px;
}
.shippingSwitch span.active {
  background-color: #000;
  color: #fff;
}
.shippingSwitch span + span.active {
  margin-left: -15px;
}
.shippingSwitch span.active:first-child {
  margin-right: -15px;
}
.cNc__wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 400px;
}
.cNc__locationsWrapper {
  width: 250px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}
.cNc__mapWrapper {
  flex-grow: 1;
}
.cNc__mapWrapper > div {
  width: 100%;
  height: 100%;
}
.cNc__locationsWrapper {
  display: flex;
  flex-direction: column;
}
.cNc__locationsSelect {
  position: relative;
  flex-grow: 0;
}
.cNc__locationsList {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  background-color: #fff;
  max-height: 450px;
  overflow: auto;
  border: 1px solid #dddddd;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  z-index: 2;
}
.cNc__locationsLabel {
  display: block;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  border-radius: 50px;
  position: relative;
  user-select: none;
  z-index: 3;
  background: #fff;
}
.cNc__locationsLabel .fa {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 2px;
  pointer-events: none;
}
.cNc__locationsRegione {
  font-weight: 700;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
}
.cNc__locationsProvincia {
  padding-left: 15px;
  cursor: pointer;
}
.cNc__puntivenditaTitle {
  margin-top: 20px;
  font-weight: 700;
  flex-grow: 0;
}
.cNc__puntivenditaList {
  flex-grow: 1;
  overflow: auto;
  font-size: 14px;
}
.cNc__puntovendita {
  padding: 8px 0;
  cursor: pointer;
}
.cNc__puntovendita + .cNc__puntovendita {
  border-top: 1px solid #e3e3e3;
}
.cNc__puntovenditaTitle {
  font-weight: 700;
}
.cNcSelected__wrapper a,
.cNc__puntovendita a {
  font-weight: normal;
  text-decoration: underline;
}
.cNcSelected__wrapper {
  margin-bottom: 10px;
}
@media (max-width: 680px) {
  .cNc__wrapper {
    flex-direction: column;
    height: auto;
  }
  .cNc__locationsWrapper {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    max-height: 300px;
    margin-bottom: 20px;
  }
  .cNc__mapWrapper {
    height: 300px;
  }
}
.button-disabled {
  opacity: .5;
  cursor: not-allowed;
}
</style>

